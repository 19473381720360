import * as React from "react"
import { Link } from "gatsby"
import { Row, Col, Button, Card, Alert } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import usePagination from "./pagination"
import { format, parseISO } from "date-fns"
import { pt } from "date-fns/locale"
import { FaArrowRight } from "react-icons/fa"

const ArticlesComponent = ({ data }) => {
  const PER_PAGE = 12
  const pageCount = Math.ceil(data.length / PER_PAGE)
  const Paginate = usePagination(data, PER_PAGE)
  return (
    <React.Fragment>
      <Row className="title padding-bottom text-center justify-content-center">
        <Col md={10}>
          <h1>Blog</h1>
          <p>Bem-vindo(a) ao nosso Blog!</p>
          <p>
            Aqui você encontrará conteúdos sobre a energia fotovoltaica, cases
            de sucesso e outras informações relevantes para você entender melhor
            o mundo dos painéis solares e suas vantagens.
          </p>
        </Col>
      </Row>
      {Paginate.currentData().length === 0 && (
        <Row>
          <Col>
            <Alert variant="primary" className="w-100">
              Nenhum post encontrado.
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        {Paginate.currentData().map(article => {
          const created_at = parseISO(article.created_at)
          return (
            <>
              <Col
                md={4}
                className="article article-card mt-2 mb-4"
                data-sal="fade"
                data-sal-duration="500"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                <Card className="h-100">
                  {article.image && (
                    <Link to={`/blog/artigo/${article.slug}`}>
                      <GatsbyImage
                        image={
                          article.image.imageFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt=""
                      />
                    </Link>
                  )}
                  <Card.Body className="bg-light-grey">
                    <h5>{article.title}</h5>
                    <p className="text-sm mb-0">{article.intro}</p>
                  </Card.Body>
                  <Card.Footer className="bg-grey">
                    <span>
                      {format(created_at, "dd 'de' MMMM 'de' yyyy", {
                        locale: pt,
                        timeZone: "America/Sao_Paulo",
                      })}
                    </span>
                    <Link
                      className="float-right"
                      to={`/blog/artigo/${article.slug}`}
                    >
                      Mais <FaArrowRight />
                    </Link>
                  </Card.Footer>
                </Card>
              </Col>
            </>
          )
        })}
      </Row>
      {data.length > PER_PAGE && (
        <Row className="mt-4">
          <Col className="text-left">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                Paginate.prev()
              }}
              disabled={Paginate.currentPage === 1 ? true : false}
            >
              Anterior
            </Button>
          </Col>
          <Col className="text-right">
            <Button
              size="sm"
              variant="secondary"
              onClick={() => {
                Paginate.next()
              }}
              disabled={Paginate.currentPage === pageCount ? true : false}
            >
              Próxima
            </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

export default ArticlesComponent
