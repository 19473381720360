import * as React from "react"
import { navigate } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Parallax } from "react-parallax"
import bgImage from "../images/parallax2.png"

export default function CtaComponent() {
  return (
    <Parallax blur={0} bgImage={bgImage} bgImageAlt="" strength={150}>
      <Container className="cta padding-top padding-bottom">
        <Row className="justify-content-center text-center">
          <Col md={12} xs={12} className="py-3">
            <h1 className="d-none d-md-inline">
              Seja um franqueado PuraGrid
              <br />e comece um negócio testado, comprovado
              <br />e com todo o suporte necessário
            </h1>
            <h1 className="d-inline d-md-none">
              Seja um franqueado PuraGrid e comece um negócio testado,
              comprovado e com todo o suporte necessário
            </h1>
            <div>
              {/* <Button
                onClick={() => navigate("/sejaumfranqueado")}
                variant="secondary"
                className="mt-5"
              >
                Quero saber mais
              </Button> */}
              <Button
                onClick={() => navigate("/sejaumfranqueado")}
                variant="secondary"
                className="mt-5"
                disabled="true"
              >
                Em breve mais informações
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Parallax>
  )
}
