import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Articles from "../components/articles"
import Cta from "../components/cta"

const ContentPage = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        articles(sort: "created_at:desc") {
          id
          created_at
          slug
          title
          intro
          content
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  aspectRatio: 2
                )
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Blog" />
      <Container className="padding-top padding-bottom">
        <Row className="justify-content-center">
          <Col md={12}>
            <Articles data={data.api.articles} />
          </Col>
        </Row>
      </Container>
      <Cta />
    </Layout>
  )
}

export default ContentPage
